<script setup lang="ts">
const { prizeDropsData, durationLeftFinished } = usePrizeDrop({
	separationTimer: true,
	isOptimizedTimer: false
});

const { t } = useT();

const handleCardClick = () => {
	navigateTo("/issues/popular-games/");
};
const handleClick = () => {
	navigateTo("/prize-drops/");
};
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 210,
			height: 120,
			src: '/nuxt-img/prize-drops/logo-promo.png',
			alt: 'prize drops',
			format: 'avif',
			loading: 'lazy'
		}"
		width="328px"
		height="325px"
		class="card-prize-drops"
		@click="handleCardClick"
	>
		<template #top>
			<ABadge background="var(--a-overlay-100)" variant="info" autosize>
				<AText type="x-small" as="div">
					<i18n-t keypath="Time left {key}">
						<template #key>
							<b>{{ durationLeftFinished }}</b>
						</template>
					</i18n-t>
				</AText>
			</ABadge>
			<AText type="small" :modifiers="['bold', 'uppercase']">
				{{ t("prizeDrops.page.title") }}
			</AText>
		</template>
		<template #bottom>
			<div class="box">
				<AText class="info-txt" as="div" :size="10" :modifiers="['center', 'uppercase']">
					{{ t("prizeDrops.page.pool") }}
				</AText>
				<MPrizeFund variant="entries" icon="28/secret-coin" :icon-size="16">
					<AText :size="28" :modifiers="['bold', 'uppercase']">
						{{ numberFormat(prizeDropsData?.general?.totalPrizePool || 0) }}
					</AText>
				</MPrizeFund>
			</div>

			<AButton :variant="'primary'" :modifiers="['wide']" size="s">
				{{ t("prizeDrops.page.button.name") }}
			</AButton>
			<AText
				:size="12"
				class="color-neutral-50"
				:modifiers="['uppercase', 'link-underline', 'bold']"
				@click.stop="handleClick"
			>
				{{ t("prizeDrops.page.link") }}
			</AText>
		</template>
	</ACard>
</template>

<style lang="scss" scoped>
.card-prize-drops {
	--a-card-default-radius: 16px;
	--a-card-default-top-gap: 12px;
	--a-card-default-bottom-gap: 12px;
	--a-card-default-bottom-padding: 0 24px 16px;
	--a-badge-info-padding: 2px 4px;
	--a-card-img-width: 140px;
	--a-card-img-height: 80px;
	--a-card-img-left: calc(50% - 70px);
	background: radial-gradient(76% 64% at 50% 35%, #019bff 21%, #0157ff 47%, #0d2bb0 74%, #160d7a 100%);
}
</style>
